"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cacheGet = exports.cacheAdd = void 0;
const VERBOSE = false;
const cache = {};

const line = function line() {
  if (VERBOSE) {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    console.log("[cached-fetch]:", ...args);
  }
};

const getKey = cacheKey => {
  return "@wk:".concat(cacheKey);
}; // I think I still need to generalize this more.
// this is essentially a memoized promise


const cacheAdd = (_ref) => {
  let {
    cacheKey,
    data,
    expiration
  } = _ref;
  line(cacheKey, data, expiration);
  const key = getKey(cacheKey);
  const created = new Date().getTime();
  const expiring = created + expiration;
  cache[key] = {
    data,
    expiring
  };
};

exports.cacheAdd = cacheAdd;

const cacheGet = cacheKey => {
  line("get", cacheKey);
  const key = getKey(cacheKey);
  const {
    data,
    expiring
  } = cache[key] || {};
  const now = new Date().getTime();

  if (!expiring) {
    line("not found in cache");
    return null;
  }

  if (now > expiring) {
    line("cache is expired", now, expiring);
    return null;
  }

  line("returning cached data");
  return data;
};

exports.cacheGet = cacheGet;