import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/auth";

import { navigate } from "@reach/router";
import styled from "styled-components";
import { colors } from "kyou-elements";
import { KyouLogo } from "../../components/KyouLogo";

const LoadingScreen = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.DARK_GREY};
  align-items: center;
  justify-content: center;
`;

const CenterPanel = styled.div`
  width: 300px;
  background-color: ${colors.DARK_GREY};
  padding: 30px;
  border-radius: 20px;
  background: linear-gradient(145deg, #23242f, #1e1f28);
  box-shadow: 10px 10px 17px #1b1c24, -10px -10px 17px #272834;
`;

const StyledLogo = styled(KyouLogo)`
  margin-bottom: 10px;
`;

export const ProtectedScene = (props) => {
  const { Scene, ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoading(false);
        // User is signed in.
      } else {
        console.log("no user");
        // No user is signed in.
        navigate("/");
      }
    });
  });

  const renderLoading = () => {
    return (
      <LoadingScreen>
        <CenterPanel>
          <StyledLogo />
        </CenterPanel>
      </LoadingScreen>
    );
  };

  return isLoading ? renderLoading() : <Scene {...rest} />;
};

ProtectedScene.propTypes = {
  Scene: PropTypes.elementType.isRequired,
};
