import React from "react";
import Clock from "react-live-clock";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Day = styled(Clock)`
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;
  color: white;
`;

const Date = styled(Clock)`
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: #d7ebba;
`;

const StyledClock = styled(Clock)`
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  color: white;
  display: block;
`;

const SmallClock = styled(Clock)`
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

const CityLabel = styled.span`
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  margin-right: 10px;
  color: #d7ebba;
`;

const minuteInterval = 1000 * 60;
export const TimeDate = () => {
  const renderTimeZone = (name, timezone) => {
    return (
      <div>
        <CityLabel>{name}</CityLabel>
        <SmallClock format="HH:mm" timezone={timezone} ticking />
      </div>
    );
  };

  return (
    <Layout>
      <Header>
        <Day
          format="dddd"
          ticking
          timezone="US/Eastern"
          interval={minuteInterval}
        />
        <Date
          format="MMM Do YYYY"
          ticking
          timezone="US/Eastern"
          interval={minuteInterval}
        />
      </Header>
      <Body>
        <StyledClock format="HH:mm" timezone="US/Eastern" ticking />
      </Body>
      <Footer>
        {renderTimeZone("Laredo", "US/Central")}
        {renderTimeZone("Tokyo", "Asia/Tokyo")}
        {renderTimeZone("Mexico City", "America/Mexico_City")}
      </Footer>
    </Layout>
  );
};
