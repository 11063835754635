import { useEffect, useState } from "react";
import moment from "moment";
import { getStateData } from "./corona-client";

const processStateData = (stateData) => {
  const sorted = stateData.sort((a, b) => {
    return a.date < b.date ? -1 : 1;
  });

  const series = sorted.map((item, index, list) => {
    const momentDate = moment(item.date, "YYYYMMDDYY");
    const positiveRollingAverage = getRollingAverage(
      index,
      list,
      "positiveIncrease",
    );

    const deathRollingAverage = getRollingAverage(index, list, "deathIncrease");

    return {
      momentDate,
      formattedDate: momentDate.format("MMM-D"),
      death: item.death || 0,
      positiveRollingAverage,
      deathRollingAverage,
      ...item,
    };
  });

  const maxPositiveIncrease = series.reduce((acc, item) => {
    return acc > item.positiveIncrease ? acc : item.positiveIncrease;
  }, 0);

  const maxDeathIncrease = series.reduce((acc, item) => {
    return acc > item.maxDeathIncrease ? acc : item.maxDeathIncrease;
  }, 0);

  // today's data
  const lastItem = series[series.length - 1];
  const { positive, death, deathIncrease, positiveIncrease } = lastItem;

  return {
    series,
    positive,
    death,
    deathIncrease,
    positiveIncrease,
    maxPositiveIncrease,
    maxDeathIncrease,
  };
};

export const groupStateData = (data) => {
  const dataByState = {};
  data.forEach((value) => {
    const { state } = value;
    if (dataByState[state] === undefined) {
      dataByState[state] = [];
    }
    dataByState[state].push(value);
  });
  return dataByState;
};

/**
 * calculates the rolling average for a specific key in the data
 */
const getRollingAverage = (index, list, key) => {
  const startIndex = Math.max(0, index - 7);
  const sublist = list.slice(startIndex, index + 1);
  const total = sublist.reduce((acc, current) => {
    return acc + (current[key] || 0);
  }, 0);
  const average = total / sublist.length;
  return average;
};

const fetchData = async () => {
  const response = await getStateData();
  const dataByState = groupStateData(response.data);

  const processedStateData = {};
  Object.keys(dataByState).forEach((key) => {
    const stateData = dataByState[key];
    processedStateData[key] = processStateData(stateData);
  });

  return processedStateData;
};

export const useCoronaData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [coronaData, setCoronaData] = useState({});

  useEffect(() => {
    const load = async () => {
      try {
        const statesSeries = await fetchData();

        setCoronaData(statesSeries);
        setIsLoading(false);
      } catch (e) {
        console.log("there was an error:");
        console.log(e);
      }
    };

    load();
  }, []);

  return { isLoading, coronaData };
};
