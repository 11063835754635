import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { KyouLogo } from "../KyouLogo";
import { colors } from "kyou-elements";

const Header = styled.div`
  color: white;
  padding: 5px 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const StyledKyouLogo = styled(KyouLogo)`
  font-size: 22px;
  line-height: 12px;
`;

const Link = styled.span`
  color: ${(props) => (props.selected ? colors.YELLOW : "white")};
  cursor: pointer;
  font-size: 12px;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
`;

const DashboardLink = styled(Link)`
  margin: 0px 20px;
  letter-spacing: 5px;
  font-size: 14px;
`;

export const DashboardHeader = (props) => {
  const { dashboardConfig = [], setScreenIndex, screenIndex, signOut } = props;
  return (
    <Header>
      <StyledKyouLogo />
      <div>
        {dashboardConfig.map((item, index) => {
          const { name } = item;
          return (
            <DashboardLink
              selected={screenIndex === index}
              key={name}
              onClick={() => {
                setScreenIndex(index);
              }}
            >
              {name}
            </DashboardLink>
          );
        })}
      </div>
      <Link onClick={signOut}>sign out</Link>
    </Header>
  );
};

DashboardHeader.propTypes = {
  dashboardConfig: PropTypes.array.isRequired,
  setScreenIndex: PropTypes.func.isRequired,
  screenIndex: PropTypes.number.isRequired,
  signOut: PropTypes.func.isRequired,
};
