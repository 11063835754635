import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Component = styled.div`
  font-size: 50px;
  color: white;
  text-align: center;
  font-weight: 600;
  font-family: "Crimson Text", serif;
`;

export const KyouLogo = ({ className }) => {
  return <Component className={className}>kyou.</Component>;
};

KyouLogo.propTypes = {
  className: PropTypes.string,
};
