import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import range from "lodash/range";
import { colors } from "kyou-elements";

import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { CoronaProjection } from "../CoronaProjection";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  overflow: hidden;
`;

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 30px 20px 30px 0px;
`;

const DeltaGroup = styled.div`
  margin-right: 50px;
  text-align: center;
`;

const Label = styled.span`
  display: block;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  color: white;
`;

const NumberLabel = styled.span`
  display: inline-block;
  margin: 0px 5px;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.YELLOW};
`;

const DeathValue = styled.span`
  display: inline-block;
  margin: 0px 5px;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.RED};
`;

const nf = new Intl.NumberFormat("en-US");

const POSITIVES_COLOR = colors.YELLOW;
const DEATHS_COLOR = colors.RED;
const AVERAGE_COLOR = "#E0A458";

export const CoronaStateChart = ({ stateData, animate = false }) => {
  const {
    series,
    positive,
    death,
    deathIncrease,
    positiveIncrease,
    maxPositiveIncrease,
  } = stateData;

  const yTicks = range(0, maxPositiveIncrease, 500);

  const renderChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={series} barCategoryGap="20%">
          <CartesianGrid stroke="#464C56" xAxisId={0} />
          <YAxis
            stroke={colors.CHART_GRAY}
            ticks={yTicks}
            domain={[0, "dataMax"]}
          />
          <XAxis
            dataKey="formattedDate"
            xAxisId={0}
            angle={-45}
            textAnchor="end"
            fontSize={10}
            stroke={colors.CHART_GRAY}
            strokeWidth={2}
          />
          <XAxis dataKey="formattedDate" xAxisId={1} hide />
          <Bar
            dataKey="positiveIncrease"
            xAxisId={0}
            fill={POSITIVES_COLOR}
            isAnimationActive={animate}
          />
          <Bar
            dataKey="deathIncrease"
            xAxisId={1}
            fill={DEATHS_COLOR}
            isAnimationActive={animate}
          />
          <Line
            type="monotone"
            dataKey="positiveRollingAverage"
            stroke={AVERAGE_COLOR}
            dot={false}
            strokeWidth={2}
            isAnimationActive={animate}
          />
          <Tooltip
            labelStyle={{ color: colors.WHITE }}
            contentStyle={{
              backgroundColor: colors.DARK_PURPLE,
              borderWidth: 0,
              borderRadius: 5,
              boxShadow: "6px 6px 12px #16171d",
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  const renderFooter = () => {
    return (
      <Footer>
        <DeltaGroup>
          <div>
            <NumberLabel>{`${nf.format(positive)}`}</NumberLabel>
            <NumberLabel style={{ fontSize: 20 }}>
              {`+${nf.format(positiveIncrease)}`}
            </NumberLabel>
          </div>
          <Label>Positive Cases</Label>
        </DeltaGroup>
        <DeltaGroup>
          <div>
            <DeathValue>{`${nf.format(death)}`}</DeathValue>
            <DeathValue style={{ fontSize: 20 }}>
              {`+${nf.format(deathIncrease)}`}
            </DeathValue>
          </div>
          <Label>Deaths</Label>
        </DeltaGroup>
        <CoronaProjection series={series} />
      </Footer>
    );
  };

  return (
    <Container>
      <ChartContainer>{renderChart()}</ChartContainer>
      {renderFooter()}
    </Container>
  );
};

CoronaStateChart.propTypes = {
  stateData: PropTypes.shape({
    series: PropTypes.array,
    positive: PropTypes.number,
    death: PropTypes.number,
    deathIncrease: PropTypes.number,
    positiveIncrease: PropTypes.number,
    maxPositiveIncrease: PropTypes.number,
  }).isRequired,
  animate: PropTypes.bool,
};
