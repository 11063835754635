import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "kyou-elements";

import { VictoryChart, VictoryLine, VictoryAxis } from "victory";
import { useFitbitWeightData } from "../../utils/useFitibitWeightData";

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const DeltaGroup = styled.div`
  margin-right: 50px;
  text-align: center;
`;

const Label = styled.span`
  display: block;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  margin-right: 10px;
  color: #d7ebba;
`;

const NumberLabel = styled.span`
  display: block;
  font-size: 30px;
  font-weight: bold;
  color: white;
`;

export const FitbitWeightChart = ({ fitbitKey, privacy }) => {
  const { isLoading, fitbitData } = useFitbitWeightData(fitbitKey);
  const {
    weightData,
    weightGoal,
    maxWeight,
    maxDelta,
    startDelta,
  } = fitbitData;
  // console.log(fitbitData);

  const renderChart = () => {
    const axisStyle = {
      axis: {
        stroke: colors.OFF_WHITE,
      },
      tickLabels: {
        fill: colors.OFF_WHITE,
        textAnchor: "middle",
        fontSize: "10px",
      },
      grid: { stroke: "#818e99", strokeWidth: 0.5 },
    };
    return (
      <>
        <VictoryChart width={1000} height={300}>
          <VictoryAxis
            key="weight axis"
            dependentAxis
            style={axisStyle}
            domain={[210, 250]}
            tickFormat={(tickValue, index, tickArray) => {
              if (privacy) {
                return "";
              }
              return tickValue;
            }}
          />

          <VictoryAxis
            key="date"
            style={axisStyle}
            tickFormat={(tickValue, index, tickArray) => {
              if (index % 5 === 0) {
                const label = tickValue.replace(/\d{4}-/g, "");
                return label;
              }
              return "";
            }}
          />

          <VictoryAxis
            key="initial data"
            style={{
              axis: {
                stroke: colors.OFF_WHITE,
                strokeDasharray: "12, 12",
              },
              tickLabels: { fill: "none" },
            }}
            axisValue={weightData[0].value}
          />

          <VictoryAxis
            key="goalLine"
            style={{
              axis: {
                stroke: colors.GREEN,
                strokeDasharray: "12, 12",
              },
              tickLabels: { fill: "none" },
            }}
            axisValue={weightGoal.weight}
          />

          <VictoryAxis
            key="maxWeight"
            style={{
              axis: {
                stroke: colors.RED,
                strokeDasharray: "12, 12",
              },
              tickLabels: { fill: "none" },
            }}
            axisValue={maxWeight}
          />

          <VictoryLine
            key="trendline"
            interpolation="natural"
            labels={(val) => {
              if (privacy) {
                return "";
              }
              const { index, data, datum } = val;
              if (data.length - 1 === parseInt(index, 10)) {
                return `${datum.value}`;
              }
              return "";
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{
              data: { stroke: colors.YELLOW },
              parent: { border: "1px solid #ccc" },
              labels: {
                fontSize: 15,
                fill: colors.WHITE,
              },
            }}
            data={weightData}
            x="dateTime"
            y="value"
          />
        </VictoryChart>
        <Footer>
          <DeltaGroup>
            <NumberLabel>{`${startDelta} lb`}</NumberLabel>
            <Label>Change from start</Label>
          </DeltaGroup>
          <DeltaGroup>
            <NumberLabel>{`${maxDelta} lb`}</NumberLabel>
            <Label>Change from max</Label>
          </DeltaGroup>
        </Footer>
      </>
    );
  };

  return <Container>{!isLoading && renderChart()}</Container>;
};

FitbitWeightChart.propTypes = {
  fitbitKey: PropTypes.string.isRequired,
  privacy: PropTypes.bool,
};
