import React from "react";
import PropTypes from "prop-types";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import { colors } from "kyou-elements";

const AVERAGE_COLOR = "#E0A458";

export const CoronaSpark = ({ series = [], className }) => {
  if (!series || series.length === 0) {
    return null;
  }

  const simpleSeries = series.map((item) => {
    const { positiveRollingAverage, formattedDate } = item;

    return {
      positiveRollingAverage,
      formattedDate,
    };
  });

  return (
    <ResponsiveContainer width="98%" height="98%">
      <LineChart data={simpleSeries}>
        <Line
          connectNulls
          dataKey="projection"
          stroke={colors.LIGHT_BLUE}
          dot={false}
          strokeWidth={2}
          strokeDasharray="5 5"
        />
        <Line
          type="monotone"
          dataKey="positiveRollingAverage"
          stroke={AVERAGE_COLOR}
          dot={false}
          strokeWidth={2}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

CoronaSpark.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
