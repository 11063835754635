import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors, Panel } from "kyou-elements";

import { CoronaStateChart } from "../../components/CoronaStateChart";

// TODO: it would be good to make a coronavirus calendar. To remember the significant things we've accomplished. For each day it should show a picture

const Layout = styled.div`
  background-color: ${colors.DARK_GREY};
  display: flex;
  flex: 1;
  padding: 50px;
  padding-bottom: 800px;
  padding-top: 200px;
`;

export const CovidScene = ({ state }) => {
  return (
    <Layout>
      <Panel title={`${state} Coronavirus`}>
        <CoronaStateChart state={state} />
      </Panel>
    </Layout>
  );
};

CovidScene.propTypes = {
  state: PropTypes.string,
};
