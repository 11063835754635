import React from "react";
import PropTypes from "prop-types";

// If this works out, maybe the Panel component should do that, and widgets should use Panel.
// OOOH, and when loading toggles, animate the Panel in. Make the neumorphic shape shadow bounceInAndOut. That could be another cool article to write.

// This part is really weird. I think this has to be a render props patterned element. Because the whole point of this is that you don't want the child rendered AND evaluated unless the condition is met. With the current pattern (write it down for the article) the child is evaluated, but not rendered.
// return <>{when && children}</>;

export const Render = ({ children, when }) => {
  // console.log("--<Render>--", when);
  return <>{when && children()}</>;
};

Render.propTypes = {
  children: PropTypes.func.isRequired,
  when: PropTypes.bool,
};
