import React from "react";
import styled from "styled-components";
import { colors } from "kyou-elements";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import { KyouLogo } from "../../components/KyouLogo";

const Scene = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.DARK_GREY};
  align-items: center;
  justify-content: center;
`;

const LoginPanel = styled.div`
  width: 300px;
  background-color: ${colors.DARK_GREY};
  padding: 30px;
  border-radius: 20px;
  background: linear-gradient(145deg, #23242f, #1e1f28);
  box-shadow: 10px 10px 17px #1b1c24, -10px -10px 17px #272834;
`;

const StyledLogo = styled(KyouLogo)`
  margin-bottom: 30px;
`;

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

export const LoginScene = () => {
  return (
    <Scene>
      <LoginPanel>
        <StyledLogo />
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </LoginPanel>
    </Scene>
  );
};
