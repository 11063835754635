import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAuthKey } from "../../utils/firestore";
import { FitbitWeightChart } from "../../components/FitbitWeightChart";

const Scene = styled.div`
  background-color: #ccc;
  flex-grow: 1;
`;

const Settings = styled.div`
  background-color: gray;
  margin: auto;
  width: 500px;
`;

const SettingsRow = styled.div`
  display: flex;
  flex: 1;
  margin: 2px;
  padding: 2px;
  justify-content: space-between;
`;

const ValueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Key = styled.div`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-size: 10px;
`;

const fitbitAuth = {
  clientId: "22BGTS",
  clientSecret: "e87f948b8b3941dbb83556f2b9f31c82",
  authUrl: "https://www.fitbit.com/oauth2/authorize",
  refreshUrl: "https://api.fitbit.com/oauth2/token",
};

export const SettingsScene = () => {
  const [fitbitKey, setFitbitKey] = useState(null);
  const goToFitbit = () => {
    const responseType = "code";
    const scope = "weight";
    const redirectUri = "https://kyou.local:3000/accept";
    console.log("--goToFitbit--");
    const url = `${fitbitAuth.authUrl}?client_id=${fitbitAuth.clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`;
    console.log(url);
    window.location.href = url;
  };

  useEffect(() => {
    const loadData = async () => {
      const fitbitCredentials = await getAuthKey("fitbit");
      // const bodyTimeSeries = await getBodyTimeSeries(
      // fitbitCredentials.access_token,
      // );
      // console.log("bodyweight");
      // console.log(bodyTimeSeries);
      // console.log(fitbitCredentials);
      setFitbitKey(fitbitCredentials.access_token);
    };
    loadData();
  });

  return (
    <Scene>
      <Settings>
        <SettingsRow>
          Fitbit settings
          <ValueRow>
            <Key>{fitbitKey}</Key>
            <button type="button" onClick={goToFitbit}>
              authorize
            </button>
          </ValueRow>
        </SettingsRow>
      </Settings>
      {fitbitKey && <FitbitWeightChart fitbitKey={fitbitKey} />}
    </Scene>
  );
};
