import React, { Component } from "react";

export class Weather extends Component {
  render() {
    return (
      <iframe
        title="weather"
        id="forecast_embed"
        type="text/html"
        frameBorder="0"
        height="245"
        width="100%"
        src="https://forecast.io/embed/#lat=42.3583&lon=-71.0603&name=Downtown Boston&color=#FFFFFF"
      />
    );
  }
}
