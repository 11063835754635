import React from "react";
// import styled from "styled-components";
import PropTypes from "prop-types";
import { Panel, DashboardLayout, Row } from "kyou-elements";
import { Timeline } from "react-twitter-widgets";

const defaultAccounts = [
  "UniversalHub",
  "liveBoston617",
  "wbz",
  "bostonpolice",
  "bostonfire",
];

export const TwitterDashboard = ({ accounts = defaultAccounts }) => {
  return (
    <DashboardLayout>
      <Row>
        {accounts.map((item) => (
          <Panel key={item} title={item}>
            <Timeline
              dataSource={{
                screenName: item,
              }}
            />
          </Panel>
        ))}
      </Row>
    </DashboardLayout>
  );
};

TwitterDashboard.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.string),
};
