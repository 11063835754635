import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import qs from "query-string";
import { updateKey } from "../../utils/firestore";
import { exchangeAccessCode } from "../../utils/fitbit";

export const AcceptAuthScene = (props) => {
  const {
    location: { search },
  } = props;

  const addRecord = async () => {
    try {
      console.log("--accepting auth--");
      const items = qs.parse(search);
      console.log(items);
      const { code } = items;
      const authObj = await exchangeAccessCode(code);
      console.log("exchange value", authObj);
      await updateKey("fitbit", authObj);
      console.log("key saved");
      navigate("/settings");
    } catch (e) {
      console.log("there was an error");
      console.log(e);
    }
  };

  useEffect(() => {
    addRecord();
  });

  return (
    <div>
      <button type="button" onClick={addRecord}>
        Dashboard
      </button>
    </div>
  );
};
