import { useEffect, useState } from "react";
import { getAuthKey } from "./firestore";

// locally cache fitbitCredentials
let fitbitCredentials;

export const useFitbitAuth = () => {
  const [fitbitKey, setFitbitKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        if (!fitbitCredentials) {
          fitbitCredentials = await getAuthKey("fitbit");
        }
        setFitbitKey(fitbitCredentials.access_token);
      } catch (e) {
        console.log("there was an error");
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  });

  return { isLoading, fitbitKey };
};
