import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import { colors } from "kyou-elements";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
`;

const ChartContainer = styled.div`
  flex: 1;
`;

const Label = styled.span`
  display: block;
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  color: white;
`;

const NumberLabel = styled.span`
  display: inline-block;
  margin: 0px 5px;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.LIGHT_BLUE};
`;

const TextContainer = styled.div`
  width: 120px;
  text-align: center;
  margin-left: 30px;
`;

const AVERAGE_COLOR = "#E0A458";

export const CoronaProjection = ({ series = [] }) => {
  if (!series || series.length === 0) {
    return null;
  }

  const latestPoint = series[series.length - 1];

  const maxPoint = series.reduce((acc, next) => {
    if (acc.positiveRollingAverage > next.positiveRollingAverage) {
      return acc;
    }
    return next;
  }, {});

  // console.log("latestPoint:", latestPoint);
  // console.log("maxPoint:", maxPoint);

  const dx = latestPoint.momentDate.diff(maxPoint.momentDate, "days");
  const dy =
    latestPoint.positiveRollingAverage - maxPoint.positiveRollingAverage;

  const m = dy / dx;
  if (isNaN(m)) {
    return null;
  }
  // days from max (x intercept)
  const daysFromMax = -maxPoint.positiveRollingAverage / m;
  const endDate = maxPoint.momentDate.add(daysFromMax, "days");
  const formattedEndDate = endDate.format("MMM-D");
  // console.log(
  // "dx",
  // dx,
  // "dy",
  // dy,
  // "slope",
  // m,
  // "x0",
  // daysFromMax,
  // "endDate",
  // formattedEndDate,
  // );

  const daysToAppend = endDate.diff(latestPoint.momentDate, "days");
  const simpleSeries = series.map((item) => {
    const { positiveRollingAverage, formattedDate } = item;
    let projection = null;
    if (formattedDate === maxPoint.formattedDate) {
      projection = maxPoint.positiveRollingAverage;
    }

    if (formattedDate === latestPoint.formattedDate) {
      projection = latestPoint.positiveRollingAverage;
    }

    return {
      positiveRollingAverage,
      formattedDate,
      projection,
    };
  });

  // console.log("days to append", daysToAppend);
  for (let i = 0; i < daysToAppend - 1; i++) {
    const newPoint = {
      positiveRollingAverage: null,
      formattedDate: null,
      projection: null,
    };
    simpleSeries.push(newPoint);
  }

  simpleSeries.push({
    positiveRollingAverage: null,
    formattedDate: formattedEndDate,
    projection: 0,
  });

  return (
    <Component>
      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={simpleSeries}>
            <Line
              connectNulls
              dataKey="projection"
              stroke={colors.LIGHT_BLUE}
              dot={false}
              strokeWidth={2}
              strokeDasharray="5 5"
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="positiveRollingAverage"
              stroke={AVERAGE_COLOR}
              dot={false}
              strokeWidth={2}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
      <TextContainer>
        <NumberLabel>{formattedEndDate}</NumberLabel>
        <Label>Time to 0</Label>
      </TextContainer>
    </Component>
  );
};

CoronaProjection.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
};
