import React from "react";
import { Panel, DashboardLayout, Row, Column } from "kyou-elements";

import { Calendar } from "../Calendar";
import { Weather } from "../Weather";
import { TimeDate } from "../TimeDate";
import { TimeLine } from "../TimeLine";

export const Dashboard = () => {
  const makePanel = (key, component, title, color) => {
    return (
      <Panel key={key} title={title} color={color}>
        {component}
      </Panel>
    );
  };

  return (
    <DashboardLayout>
      <Row>
        <Column size={2}>{makePanel("timeline", <TimeLine />)}</Column>
        <Column size={10}>
          <Row size={4}>
            {makePanel("time", <TimeDate />)}
            {makePanel("weat", <Weather />, null, "#C7CEDB")}
          </Row>
          <Row size={8}>
            {makePanel("rcal", <Calendar person="raf" />, "Raf")}
            {makePanel("lcal", <Calendar person="lindsay" />, "Lindsay")}
          </Row>
        </Column>
      </Row>
    </DashboardLayout>
  );
};
