import { setup } from "axios-cache-adapter";
import formurlencoded from "form-urlencoded";

const baseURL = "https://api.fitbit.com";

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const CACHE_MINUTES = 60 * 2;
export const fitbitClient = setup({
  // axios options
  baseURL,

  // `axios-cache-adapter` options
  cache: {
    maxAge: CACHE_MINUTES * 60 * 1000,
  },
});

// This one needs to be a manual fetch call.
export const exchangeAccessCode = (code) => {
  const redirectUri = "https://kyou.local:3000/accept";
  const authUrl = "https://api.fitbit.com/oauth2/token";
  const clientId = "22BGTS";
  const clientSecret = "e87f948b8b3941dbb83556f2b9f31c82";
  const secret = btoa(`${clientId}:${clientSecret}`);

  return fetch(authUrl, {
    method: "POST",
    headers: {
      Authorization: `Basic ${secret}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formurlencoded({
      code,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      client_id: clientId,
      expires_in: 31536000,
    }),
  }).then((response) => {
    return response.json();
  });
};

export const getWeightData = (fitbitKey) => {
  return fitbitClient.get(
    "/1/user/-/body/log/weight/date/2020-03-25/30d.json",
    {
      headers: {
        Authorization: `Bearer ${fitbitKey}`,
        "Accept-Language": "en_US",
      },
    },
  );
};

export const getWeightGoalData = async (fitbitKey) => {
  const path = "/1/user/-/body/log/weight/goal.json";
  const response = await fitbitClient.get(path, {
    headers: {
      Authorization: `Bearer ${fitbitKey}`,
      "Accept-Language": "en_US",
    },
  });
  console.log("getWeightGoaldData from cache?", response.request.fromCache);
  return response.data;
};

export const getRecentBodyTimeSeries = async (fitbitKey) => {
  const path = `/1/user/-/body/weight/date/today/30d.json`;
  const response = await fitbitClient.get(path, {
    headers: {
      Authorization: `Bearer ${fitbitKey}`,
      "Accept-Language": "en_US",
    },
  });
  console.log(
    "getRecentBodyTimeSeries from cache?",
    response.request.fromCache,
  );
  return response.data["body-weight"];
};

export const getBodyTimeSeriesForYear = async (fitbitKey, year) => {
  const path = `/1/user/-/body/weight/date/${year}-01-01/${year}-12-31.json`;
  const response = await fitbitClient.get(path, {
    headers: {
      Authorization: `Bearer ${fitbitKey}`,
      "Accept-Language": "en_US",
    },
  });
  console.log(
    "getBodyTimeSeriesForYear from cache?",
    response.request.fromCache,
  );
  return response.data;
};

export const getBodyTimeSeries = (fitbitKey) => {
  return Promise.all(
    [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020].map((year) =>
      getBodyTimeSeriesForYear(fitbitKey, year),
    ),
  ).then((results) => {
    return results.reduce((acc, current) => {
      return acc.concat(current["body-weight"]);
    }, []);
  });
};
