import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import firebase from "firebase/app";
import { colors } from "kyou-elements";
import { dashboardConfig } from "../../dashboardConfig";
import { DashboardHeader } from "../../components/DashboardHeader";

const Scene = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.DARK_GREY};
`;

const DashContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DashboardScene = (props) => {
  const { dashIndex } = props;
  const parsedIndex = parseInt(dashIndex, 10) || 0;

  const [screenIndex, setScreenIndex] = useState(parsedIndex);

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      window.location.reload();
      // signed out
    } catch (e) {
      console.log("--there was an error signing out---");
      // an error
    }
  };

  const CurrentDash = dashboardConfig[screenIndex].component;

  return (
    <Scene>
      <DashContainer>
        <CurrentDash />
      </DashContainer>
      <DashboardHeader
        dashboardConfig={dashboardConfig}
        setScreenIndex={setScreenIndex}
        screenIndex={screenIndex}
        signOut={signOut}
      />
    </Scene>
  );
};

DashboardScene.propTypes = {
  dashIndex: PropTypes.string,
};
