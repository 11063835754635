// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

let db;

export const firebaseConfig = {
  apiKey: "AIzaSyAMee8lVOclFduVx8Lhzrxd2KRVsPwyu0o",
  authDomain: "kyou-dashboard.firebaseapp.com",
  databaseURL: "https://kyou-dashboard.firebaseio.com",
  projectId: "kyou-dashboard",
  storageBucket: "kyou-dashboard.appspot.com",
  messagingSenderId: "264227626762",
  appId: "1:264227626762:web:44f53343e18360e336a4f3",
};

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  db = firebase.firestore();
};

export const getDb = () => {
  return db;
};
