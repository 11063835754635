import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "kyou-elements";

// const template = "dd MMM D";
const View = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TimelineContainer = styled.div`
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0px;
`;

const Weekday = styled.span`
  color: ${colors.OFF_WHITE};
  margin-bottom: -3px;
`;

const Date = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.OFF_WHITE};
`;

const Day = styled.span`
  border-radius: 5px;
  width: 45px;
  height: 20px;
  margin-bottom: 4px;
  font-family: monospace;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Month = styled.div`
  font-family: "Muli", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  color: ${colors.WHITE};
`;

// border-bottom: 1px solid rgba(255, 255, 255, 0.05);
const DayRow = styled.div`
  padding: 5px;
  margin: 1px;
  background-color: ${(props) => (props.isWeekend ? colors.OFF_BLUE : null)};
  border-bottom: ${(props) =>
    props.hasDivider ? "1px solid rgba(255, 255, 255, 0.05)" : null};
`;

const weekends = ["Sa", "Su"];
const dividers = ["Mo", "Tu", "We", "Th"];

export class TimeLine extends Component {
  renderDay = (m) => {
    const weekDay = m.format("dd");
    const day = m.format("D");
    const isWeekend = weekends.includes(weekDay);
    const hasDivider = dividers.includes(weekDay);

    return (
      <DayRow isWeekend={isWeekend} hasDivider={hasDivider}>
        <Day>
          <Weekday>{weekDay}</Weekday>
          <Date>{day}</Date>
        </Day>
      </DayRow>
    );
  };

  renderMonth = (m) => {
    const month = m.format("MMMM");
    return <Month key={month}>{month}</Month>;
  };

  renderContent() {
    const now = moment();
    now.add(-1, "d");
    const dayArray = Array(30).fill(0);
    return (
      <>
        {dayArray.map((day) => {
          now.add(1, "d");
          return (
            <React.Fragment key={now}>
              {now.format("D") === "1" && this.renderMonth(now)}
              <div>{this.renderDay(now)}</div>
            </React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    const now = moment();
    return (
      <View style={{ flex: 1 }}>
        {this.renderMonth(now)}
        <TimelineContainer>{this.renderContent()}</TimelineContainer>
      </View>
    );
  }
}
