import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import AwesomeSlider from "react-awesome-slider";
import firebase from "firebase/app";
import { colors } from "kyou-elements";
import "react-awesome-slider/dist/styles.css";
import { dashboardConfig } from "../../dashboardConfig";
import { DashboardHeader } from "../../components/DashboardHeader";
import "./style.css";

const Scene = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.DARK_GREY};
`;

const DashContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ExpandedSlide = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

// const TEN_SECONDS_MS = 10 * 1000;
const TEN_MINUTES_MS = 10 * 60 * 1000;
const INTERVAL = TEN_MINUTES_MS;

export const CarouselScene = (props) => {
  const [screenIndex, setScreenIndex] = useState(0);

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      window.location.reload();
      // signed out
    } catch (e) {
      console.log("--there was an error signing out---");
      // an error
    }
  };

  // we need this because the timer's closure is weird.
  const indexRef = useRef(screenIndex);
  indexRef.current = screenIndex;

  useEffect(() => {
    const timer = setInterval(() => {
      const index = indexRef.current;
      const nextIndex = (index + 1) % dashboardConfig.length;
      setScreenIndex(nextIndex);
    }, INTERVAL);
    return () => clearInterval(timer);
  }, []);

  const renderDash = (dashConfig) => {
    const { component: Component, name } = dashConfig;

    return (
      <div key={name}>
        <ExpandedSlide>
          <Component />
        </ExpandedSlide>
      </div>
    );
  };

  const renderRegular = () => {
    return (
      <AwesomeSlider
        className="raf-slider"
        bullets={false}
        organicArrows={false}
        fillParent
        selected={screenIndex}
      >
        {dashboardConfig.map(renderDash)}
      </AwesomeSlider>
    );
  };

  return (
    <Scene>
      <DashContainer>{renderRegular()}</DashContainer>
      <DashboardHeader
        dashboardConfig={dashboardConfig}
        setScreenIndex={setScreenIndex}
        screenIndex={screenIndex}
        signOut={signOut}
      />
    </Scene>
  );
};
