"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Column = exports.Row = exports.DashboardLayout = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _colors = require("./colors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject3() {
  const data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  flex: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  const data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const DashboardLayout = _styledComponents.default.div(_templateObject(), _colors.colors.DARK_GREY);

exports.DashboardLayout = DashboardLayout;

const Row = _styledComponents.default.div(_templateObject2(), props => props.size || 1);

exports.Row = Row;

const Column = _styledComponents.default.div(_templateObject3(), props => props.size || 1);

exports.Column = Column;