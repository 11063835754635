import React, { Component } from "react";
import PropTypes from "prop-types";

export class Calendar extends Component {
  render() {
    const { person = "" } = this.props;
    const calendarProps = {
      style: { borderWidth: 0 },
      frameBorder: "0",
      scrolling: "no",
      width: "100%",
      height: "100%",
    };

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {person.toLowerCase() === "raf" && (
            <iframe
              title="raf"
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=ZHNsb3VuZ2VAZ21haWwuY29t&amp;src=Mm51ZWQwaGNxZjN1bGdlZXQwcm9qYTV0aDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=cm1lbmRpb2xhQGh1YnNwb3QuY29t&amp;color=%237986CB&amp;color=%234285F4&amp;color=%23EF6C00&amp;showTitle=0&amp;showNav=0&amp;showDate=0&amp;mode=AGENDA&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0"
              {...calendarProps}
            />
          )}

          {person.toLowerCase() === "lindsay" && (
            <iframe
              title="lindsay"
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;showTitle=0&amp;showNav=0&amp;showDate=0&amp;mode=AGENDA&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;src=cjFsb3EzajZmMThmcmY5aW5zNmwxbmdxdGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmVra3lvQGdtYWlsLmNvbQ&amp;color=%23616161&amp;color=%23B39DDB"
              {...calendarProps}
            />
          )}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  person: PropTypes.string,
};
