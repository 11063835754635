import { setup } from "axios-cache-adapter";

const baseURL = "https://covidtracking.com";

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
export const coronaClient = setup({
  // axios options
  baseURL,

  // `axios-cache-adapter` options
  cache: {
    maxAge: 30 * 60 * 1000,
  },
});

export const getStateData = () => {
  return coronaClient.get("/api/v1/states/daily.json");
};
