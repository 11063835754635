import React from "react";
import { Panel, DashboardLayout, Row, Column } from "kyou-elements";
import { useFitbitAuth } from "../../utils/useFitbitAuth";

import { FitbitWeightChart } from "../FitbitWeightChart";

export const HealthDashboard = () => {
  const { isLoading, fitbitKey } = useFitbitAuth();

  return (
    <DashboardLayout>
      <Column>
        <Row>
          <Panel title={"Raf's Weight"}>
            {!isLoading && <FitbitWeightChart fitbitKey={fitbitKey} privacy />}
          </Panel>
        </Row>
        <Row />
      </Column>
    </DashboardLayout>
  );
};
