import { useEffect, useState } from "react";
import roundTo from "round-to";
import { getWeightGoalData, getRecentBodyTimeSeries } from "./fitbit";
import { findMaxWeight } from "./findMaxWeight";

export const useFitbitWeightData = (fitbitKey) => {
  const [fitbitData, setFitbitData] = useState({
    weightData: null,
    weightGoal: null,
    maxWeight: null,
    maxDelta: null,
    startDelta: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadFitbit = async () => {
      try {
        const [weightGoalData, weightSeries] = await Promise.all([
          getWeightGoalData(fitbitKey),
          getRecentBodyTimeSeries(fitbitKey),
        ]);

        const weightData = weightSeries.map((item) => {
          return {
            ...item,
            value: parseFloat(item.value),
          };
        });

        const maxWeight = findMaxWeight(weightData);
        const lastWeight = weightData[weightData.length - 1].value;
        const firstWeight = weightData[0].value;

        const maxDelta = roundTo(lastWeight - maxWeight, 2);
        const startDelta = roundTo(lastWeight - firstWeight, 2);

        setFitbitData({
          weightData,
          weightGoal: weightGoalData.goal,
          maxWeight,
          maxDelta,
          startDelta,
        });
      } catch (e) {
        console.log("there was an error");
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    loadFitbit();
  }, [fitbitKey]);

  return { isLoading, fitbitData };
};
