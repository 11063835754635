import React from "react";
import { WanikaniDashboard, setAuthorization } from "wk-components";
import { Dashboard } from "./components/Dashboard";
// import { TimeDashboard } from "./components/TimeDashboard";
import { HealthDashboard } from "./components/HealthDashboard";
import { CoronaDashboard } from "./components/CoronaDashboard";
import { TwitterDashboard } from "./components/TwitterDashboard";

const defaultMinutes = 5; // 0.11

setAuthorization("cf3ba723-a761-4f5e-b8b5-c5d1e29b3b94");

const JapaneseDashboard = () => {
  // const TOKEN = "cf3ba723-a761-4f5e-b8b5-c5d1e29b3b94"; // mine
  // const TOKEN = "950f9b67-0e73-4c7c-b532-30d713820a12"; // Derek's
  // setAuthorization(TOKEN);

  // const apiKey = "cf3ba723-a761-4f5e-b8b5-c5d1e29b3b94"; // mine
  return <WanikaniDashboard /* apiKey={apiKey} */ />;
};

const bostonAccounts = [
  "UniversalHub",
  "liveBoston617",
  "wbz",
  "bostonpolice",
  "bostonfire",
];

const laredoAccounts = [
  "cityoflaredo",
  "lmtnews",
  "mylaredopd",
  "laredohealth",
  "KGNSnews",
];

const BostonTweets = () => {
  return <TwitterDashboard accounts={bostonAccounts} />;
};

const LaredoTweets = () => {
  return <TwitterDashboard accounts={laredoAccounts} />;
};

const makeInterval = (minutes) => {
  return minutes * 60 * 1000;
};

export const dashboardConfig = [
  {
    name: "main",
    component: Dashboard,
    interval: makeInterval(defaultMinutes),
  },
  {
    name: "health",
    component: HealthDashboard,
    interval: makeInterval(defaultMinutes),
  },
  {
    name: "coronavirus",
    component: CoronaDashboard,
    interval: makeInterval(defaultMinutes),
  },
  {
    name: "wanikani",
    component: JapaneseDashboard,
    interval: makeInterval(defaultMinutes),
  },
  {
    name: "boston",
    component: BostonTweets,
    interval: makeInterval(defaultMinutes),
  },
  {
    name: "laredo",
    component: LaredoTweets,
    interval: makeInterval(defaultMinutes),
  },
  // { name: "time", component: TimeDashboard, minutes: defaultMinutes },
];
