import React from "react";
import { Router } from "@reach/router";

import styled from "styled-components";
import { DashboardScene } from "./scenes/DashboardScene";
import { CarouselScene } from "./scenes/CarouselScene";
import { LoginScene } from "./scenes/LoginScene";
import { CovidScene } from "./scenes/CovidScene";
import { SettingsScene } from "./scenes/SettingsScene";
import { ProtectedScene } from "./scenes/ProtectedScene";
import { AcceptAuthScene } from "./scenes/AcceptAuthScene";
import { initializeFirebase } from "./firebase";

initializeFirebase();

const StyledRouter = styled(Router)`
  display: flex;
  flex: 1;
`;

function App() {
  return (
    <StyledRouter>
      <LoginScene path="/login" />
      <CovidScene path="/covid/:state" />
      <ProtectedScene Scene={DashboardScene} path="/:dashIndex" />
      <ProtectedScene Scene={DashboardScene} path="/" />
      <ProtectedScene Scene={CarouselScene} path="/carousel" />
      <ProtectedScene Scene={SettingsScene} path="/settings" />
      <ProtectedScene Scene={AcceptAuthScene} path="/accept" />
    </StyledRouter>
  );
}

export default App;
