import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "kyou-elements";
import { CoronaSpark } from "../CoronaSpark";

const Component = styled.div`
  flex: 1;
  overflow: scroll;
  max-height: 1000px;
  padding-top: 20px;
`;

const Row = styled.div`
  padding-bottom: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: -30px;
`;

const StateLabel = styled.div`
  color: ${colors.WHITE};
  font-size: 20px;
  margin-right: 10px;
  font-family: "Muli", sans-serif;
  width: 50px;
`;

const ChartContainer = styled.div`
  display: flex;
  flex: 1;
  height: 50px;
  width: 100px;
`;

export const CoronaTrends = ({ coronaData }) => {
  return (
    <Component>
      {Object.keys(coronaData).map((key) => {
        const stateData = coronaData[key];
        const { series } = stateData;
        return (
          <Row key={key}>
            <StateLabel>{key}</StateLabel>
            <ChartContainer>
              <CoronaSpark series={series} />
            </ChartContainer>
          </Row>
        );
      })}
    </Component>
  );
};

CoronaTrends.propTypes = {
  coronaData: PropTypes.object,
};
