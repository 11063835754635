import { getDb } from "../firebase";

export const getAuthKey = async (service) => {
  const db = getDb();
  const snapshot = await db
    .collection("auth_keys")
    .where("service", "==", service)
    .get();
  const doc = snapshot.docs[0];
  const { credentials } = doc.data();
  return credentials;
};

export const updateKey = (key, credentials) => {
  console.log("--updateKey--", key, credentials);
  const db = getDb();
  return db
    .collection("auth_keys")
    .where("service", "==", key)
    .get()
    .then((snapshot) => {
      const doc = snapshot.docs[0];
      // Build doc ref from doc.id
      db.collection("auth_keys").doc(doc.id).update({ credentials });
    });
};
