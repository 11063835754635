/* eslint-disable dot-notation */
import React from "react";
import { Panel, DashboardLayout, Row, Column } from "kyou-elements";
import { CoronaStateChart } from "../CoronaStateChart";
import { CoronaTrends } from "../CoronaTrends";
import { useCoronaData } from "../../utils/corona/useCoronaData";
import { Render } from "../Render";

// TODO: it would be good to make a coronavirus calendar. To remember the significant things we've accomplished. For each day it should show a picture

export const CoronaDashboard = () => {
  const { isLoading, coronaData } = useCoronaData();
  return (
    <DashboardLayout>
      <Render when={!isLoading}>
        {() => (
          <>
            <Row>
              <Column style={{ flex: 1 }}>
                <Panel title="All States">
                  <CoronaTrends coronaData={coronaData} />
                </Panel>
              </Column>
              <Column style={{ flex: 4 }}>
                <Row style={{ flex: 2 }}>
                  <Panel title="Massachusetts">
                    <CoronaStateChart stateData={coronaData["MA"]} />
                  </Panel>
                </Row>
                <Row>
                  <Panel title="Texas">
                    <CoronaStateChart stateData={coronaData["TX"]} />
                  </Panel>
                  <Panel title="Florida">
                    <CoronaStateChart stateData={coronaData["FL"]} />
                  </Panel>
                  <Panel title="New York">
                    <CoronaStateChart stateData={coronaData["NY"]} />
                  </Panel>
                </Row>
              </Column>
            </Row>
          </>
        )}
      </Render>
    </DashboardLayout>
  );
};
